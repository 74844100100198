import React, { useState, useEffect } from "react"
import { MenuList } from "../components/MenuList"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import Search from "../components/Search"
import Logo from "../assets/logo.svg"
import { Button } from "../components/Buttons"

export function Navbar() {
  const [menuState, toggleMenu] = useState("NO_OPEN")

  useEffect(() => {
    const validateIfIsWindows = e => {
      if (e.target.innerWidth > 1000) {
        toggleMenu("WINDOWS")
      } else {
        toggleMenu("NO_OPEN")
      }
    }
    window.addEventListener("resize", validateIfIsWindows)

    return () => window.removeEventListener("resize", validateIfIsWindows)
  }, [])

  return (
    <section className="container md:w-10/12 mx-auto">
      <nav className="flex items-center justify-between flex-wrap p-6">
        <Link to="/">
          <div className="flex items-center flex-shrink-0 mr-6">
            <Logo className="fill-current h-8 md:w-8 xs:w-12 mr-2" />

            <span className="font-semibold md:text-xl xs:text-2xl tracking-tight">
              <FormattedMessage id="title" />
            </span>
          </div>
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={() =>
              toggleMenu(s => (s === "NO_OPEN" ? "OPEN" : "NO_OPEN"))
            }
            className="flex items-center px-3 py-2 border rounded  hover:border-black"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={` ${
            menuState === "WINDOWS"
              ? "h-auto"
              : menuState === "OPEN"
              ? "xs:h-56"
              : "xs:h-0"
          } overflow-hidden md:h-auto w-full block flex-grow lg:flex lg:items-center lg:w-auto transition-all duration-500 ease-in-out`}
        >
          <MenuList />
          <div>
            <span className="inline-block text-sm leading-none mt-4 lg:mt-0">
              <Search />
            </span>

            <Link to="/blog">
              <Button primary>Blog</Button>
            </Link>
          </div>
        </div>
      </nav>
    </section>
  )
}
