import React from "react"
import styled, { css } from "styled-components"
import { Algolia } from "styled-icons/fa-brands/Algolia"
export const Root = styled.div`
  position: relative;
  display: block;
`

const collapse = css``
const expand = css`
  background: white;
  color: darkgray;
  width: 90%;
`

export const Input = styled.input`
  width: 90%;
  background: #004b680d;
  padding: 0.5em;
  outline-color: #1c27456b;
  transition: 1s ease;
  border-radius: 8px;
  margin-right: 1em;
  {hightlight-next-line}
  ${props => (props.collapse ? collapse : expand)};
`
export const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`
//
export const HitsWrapper = styled.div`
  max-height: 80vh;
  color: darkgray;
  overflow: hidden scroll;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5em);
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 0.7em 1em 0.4em;
  background: white;
  border-radius: 10px;
  > * + * {
    padding-top: 1em !important;
    border-top: 2px solid gray;
  }
  li + li {
    margin-top: 0.7em;
    padding-top: 0.7em;
    border-top: 1px solid gray;
  }
  * {
    margin-top: 0;
  }
  ul {
    list-style: none;
  }
  mark {
    color: #4a5568;
    background: #e2e8f0;
  }
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
    h3 {
      color: white;
      background: gray;
      padding: 0.1em 0.4em;
      border-radius: 10px;
    }
  }
  h3 {
    margin: 0 0 0.5em;
  }
  h4 {
    margin-bottom: 0.3em;
  }
`

export const PoweredBy = () => (
  <div>
    Powered by{` `}
    <a href="https://algolia.com">
      <Algolia size="1em" /> Algolia
    </a>
  </div>
)
