import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import favicon16 from "../images/favicon.ico"
import { orgaCreator, getSchemaArticle } from "../utils/helper"

function SEO({
  description,
  meta,
  title,
  image,
  intlId = "personal",
  isBlog,
  updatedAt,
  createdAt,
}) {
  const { site, allImageSharp } = useStaticQuery(
    graphql`
      query {
        allImageSharp(filter: { original: { src: { regex: "/ogaston/" } } }) {
          edges {
            node {
              id
              original {
                src
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            titleAlt
          }
        }
      }
    `
  )

  let currentUrl = site.siteMetadata.siteUrl
  if (typeof window != "undefined") {
    currentUrl = window.location.href
  }

  const intlMessages = useMessages(intlId)
  const src = useSeoImageFromAllImageSharp(allImageSharp)
  const metaDescription = meta || intlMessages.metaDescription
  const ogDescription = metaDescription || intlMessages.metaDescription
  image = image || site.siteMetadata.siteUrl + src
  title = title || intlMessages.title

  const creator = orgaCreator({
    siteUrl: site.siteMetadata.siteUrl,
    homeURL: site.siteMetadata.siteUrl,
    siteTitle: site.siteMetadata.title,
    siteTitleAlt: site.siteMetadata.titleAlt,
    siteDescription: site.siteMetadata.description,
    siteLogo: site.siteMetadata.siteUrl + src,
    siteLogoSmall: site.siteMetadata.siteUrl + src,
  })

  const article = getSchemaArticle({
    siteUrl: site.siteMetadata.siteUrl,
    URL: currentUrl,
    htmlLang: intlMessages.locale || "en",
    image: image,
    title: title,
    description,
    createdAt,
    updatedAt,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang: intlMessages.locale || "en",
      }}
      title={title || site.siteMetadata.titleAlt}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        { charSet: "UTF-8" },
        {
          name: `description`,
          content: description || intlMessages.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${image}`,
        },
        {
          property: `og:image:secure_url`,
          content: `${image}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: `${image}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `MIm2S7lGfR01Ar1AGtvKO-DKzf7vzikiAxp3FWx_W30`,
        },
      ]}
      link={[
        {
          rel: "icon",
          type: "image/ico",
          sizes: "16x16",
          href: `${favicon16}`,
        },
      ]}
    >
      {/* {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>} */}
      <script type="application/ld+json">
        {JSON.stringify(creator("ogaston"))}
      </script>
      {isBlog && (
        <script type="application/ld+json">{JSON.stringify(article)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
}

function useMessages(id) {
  const intl = useIntl()

  const obj = {
    title: "",
    description: "",
    metaDescription: "",
    website: "",
    summary: "",
    lang: intl.locale,
  }

  if (id === "personal") {
    obj.title =
      intl.formatMessage({ id: "personal.fullname" }) +
      " ..." +
      intl.formatMessage({ id: "personal.title" })
    obj.description = intl.formatMessage({ id: "personal.presentation" })

    obj.metaDescription = intl.formatMessage({ id: "personal.coverletter" })
  }

  if (id === "blog") {
    obj.title = intl.formatMessage({ id: "blog.subtitle" }).split(".")[0]
    obj.description =
      "Developer Blog, " +
      intl.formatMessage({ id: "blog.title" }) +
      " " +
      intl.formatMessage({ id: "blog.title" })
    obj.metaDescription = intl.formatMessage({ id: "aptitudes.community.text" })
  }

  if (id === "projects") {
    obj.title = intl.formatMessage({ id: "projects.title" })
    obj.description = intl.formatMessage({ id: "projects.text" })
    obj.metaDescription = intl.formatMessage({ id: "projects.text" })
  }

  if (id === "contact") {
    obj.title = intl.formatMessage({ id: "contact.title" })
    obj.description = intl.formatMessage({ id: "contact.text" })
    obj.metaDescription = intl.formatMessage({ id: "contact.text" })
  }

  obj.metaDescription =
    obj.metaDescription.length > 250
      ? obj.metaDescription.substring(0, 255) + "..."
      : obj.metaDescription

  return obj
}

function useSeoImageFromAllImageSharp(allImageSharp) {
  const { edges } = allImageSharp
  return edges[0].node.original.src
}

export default SEO
