import React from "react"
export default function Labels({ list, classStyle = "" }) {
  const defaultStyle =
    "inline-block bg-gray-200 rounded-full px-3 py-1 sm:text-xs xs:text-xs text-sm font-semibold text-gray-700 mr-2"
  return (
    <div className="hidden xs:flex lg:flex md:flex sm:flex xl:flex">
      {list.map((label, i) => (
        <span key={i} className={defaultStyle + " " + classStyle}>
          #{label}
        </span>
      ))}
    </div>
  )
}
