/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { Header } from "./Header"
import Footer from "../components/Footer"
import "../styles.css"

const Layout = ({ children, frontBanner, blogImg, notFound }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryLay {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div>
          <Header />
          <div>
            <main className="min-h-screen">{children}</main>
            <Footer />
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
