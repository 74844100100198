import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const MenuList = ({ textColor }) => {
  const menuList = ["knowme", "projects", "contact"]
  const classStyle =
    "block mt-4 lg:inline-block lg:mt-0 mr-6 nav-option " + textColor
  return (
    <div className="text-sm lg:flex-grow lg:pr-12 md:p-0 xs:p-4">
      {menuList.map((item, i) => {
        const route = item === "knowme" ? "" : item
        return (
          <Link to={"/" + route} className={classStyle} key={i}>
            <FormattedMessage
              className="nav-option"
              id={`navbar.${item}`}
              key={i}
            />
          </Link>
        )
      })}
    </div>
  )
}

MenuList.propTypes = {
  textColor: PropTypes.string,
}

MenuList.defaultProps = {
  textColor: "white",
}

export { MenuList }
