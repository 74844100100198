import React, { useState, useEffect, createRef } from "react"
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { Root, PoweredBy } from "./styles"
import Input from "./input"
import * as hitComps from "./hitComps"
import { injectIntl } from "react-intl"

const Results = injectIntl(
  connectStateResults(
    ({ searchState: state, searchResults: res, children, intl }) =>
      res && res.nbHits > 0 ? (
        children
      ) : (
        <span className="font-semibold text-2xl">
          {`${intl.formatMessage({ id: "search.noresult" })} '${state.query}'`}
        </span>
      )
  )
)

const Stats = injectIntl(
  connectStateResults(
    ({ searchResults: res, intl }) =>
      res &&
      res.nbHits > 0 &&
      `${res.nbHits} ${intl.formatMessage({ id: "search.result" })}${
        res.nbHits > 1 ? `s` : ``
      }`
  )
)

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`]
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler()

  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}

function Search({ indices = [], collapse, hitsAsGrid, intl }) {
  let thisIndex = [
    { name: `blogs`, title: `Blogs`, hitComp: `BlogHit`, lang: "en" },
    {
      name: `blogsEs`,
      title: `Blogs en Español`,
      hitComp: `BlogHit`,
      lang: "es",
    },
  ]
  thisIndex = thisIndex.filter(i => i.lang === intl.locale)
  const localIndices = indices.length ? indices : thisIndex

  const ref = createRef()
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  useClickOutside(ref, () => setFocus(false))
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={localIndices[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
      root={{ Root, props: { ref } }}
      style={{ width: "90%", margin: "auto" }}
    >
      <Input
        onFocus={() => setFocus(true)}
        placeholder={intl.formatMessage({ id: "search.search" })}
        {...{ collapse, focus }}
      />
      <div
        className="rounded fixed inset-0 p-8 mt-16 w-8/12 mx-auto"
        style={{
          display: query.length > 0 && focus ? "block" : "none",
          background: "#f7fafcba",
        }}
      >
        {localIndices.map(({ name, title, hitComp }) => (
          <Index key={name} indexName={name}>
            <div className="text-center">
              <header>
                <h3>{title}</h3>
                <div className="mt-8 font-semibold text-2xl">
                  <Stats />
                </div>
              </header>
              <Results>
                <Hits
                  hitComponent={hitComps[hitComp](
                    () => setFocus(false),
                    intl.locale
                  )}
                />
              </Results>
            </div>
          </Index>
        ))}
        <div
          style={{
            color: "#d1d1d1",
            fontSize: "0.75em",
            float: "right",
            marginTop: "70vh",
          }}
        >
          <PoweredBy />
        </div>
      </div>
    </InstantSearch>
  )
}

export default injectIntl(Search)
