import React from "react"
import { Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { faGithub } from "@fortawesome/free-brands-svg-icons"

const GitHubButton = ({ link, shortSize = false, children }) => (
  <LButton
    link={link}
    shortSize={shortSize}
    icon={faGithub}
    iconClassStyle={"fill-current w-4 h-4 " + !shortSize ? "mr-2" : ""}
    buttonClassStyle={"btn btn-gray-outline"}
  >
    {children}
  </LButton>
)

const ProjectButton = ({ link, shortSize = false, children }) => (
  <LButton
    link={link}
    shortSize={shortSize}
    icon={faGlobe}
    iconClassStyle={"fill-current w-4 h-4 " + !shortSize ? "mr-2" : ""}
    buttonClassStyle={"btn btn-blue mr-3 xs:mb-3"}
  >
    {children}
  </LButton>
)

const Linkable = ({ link, children }) =>
  link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <span>{children}</span>
  )

const CreatedButton = ({
  buttonClassStyle,
  iconClassStyle,
  icon,
  shortSize,
  children,
}) => (
  <button className={buttonClassStyle}>
    <FontAwesomeIcon icon={icon} className={iconClassStyle} />
    {!shortSize ? children : ""}
  </button>
)

const LButton = props => {
  return (
    <Linkable {...props}>
      <CreatedButton {...props}>{props.children}</CreatedButton>
    </Linkable>
  )
}

const DefaultButton = ({ text }) => (
  <button className="mb-2 bg-white mr-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
    {text}
  </button>
)

const ColoredLink = ({ to, children, classStyle }) => (
  <Link to={to}>
    <span className={`hover:${classStyle}`} style={{ transition: "1s ease" }}>
      {children}
    </span>
  </Link>
)

const SubscribeButton = ({ children }) => (
  <Link
    to="/"
    className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:text-yellow-400 mt-4 lg:mt-0"
  >
    {children}
  </Link>
)

const GoHome = ({ children, isFullSize }) => (
  <Link to="/blog">
    <button
      className={
        (isFullSize ? "w-full" : "") +
        " justify-center bg-gray-100 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
      }
    >
      <FontAwesomeIcon icon={faHome} className="fill-current w-4 h-4 mr-2" />
      <span>{children}</span>
    </button>
  </Link>
)

const Button = ({ children, primary, outline }) => {
  let classStyle = "btn "

  classStyle += primary ? " btn-blue-outline" : ""

  classStyle += outline ? " btn btn-blue" : ""

  return <button className={classStyle + " mr-3"}>{children}</button>
}

export {
  GitHubButton,
  DefaultButton,
  SubscribeButton,
  GoHome,
  Button,
  ProjectButton,
  ColoredLink,
}
