import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

const styles = {
  container: { margin: "0 auto", maxWidth: "90%", textAlign: "center" },
}

const Footer = () => {
  return (
    <footer className="sm:p-2 md:p-3 lg:p-3" style={styles.container}>
      <div className="pb-3">
        © {new Date().getFullYear()}, made with{" "}
        {
          <FontAwesomeIcon
            icon={faHeart}
            className="mt-1 mr-1 ml-1 p1 text-red-400"
          />
        }{" "}
        by
        {` `}
        <a href="https://twitter.com/ogastonc">@ogastonc</a>
      </div>
    </footer>
  )
}

export default Footer
