import React from "react"

export function Container({ children, fluid }) {
  return (
    <section
      className={`container xs:p-2 mx-auto md:p-8 ${
        fluid ? "md:w-8/12 xs:w-full" : ""
      }`}
    >
      {children}
    </section>
  )
}
