import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { Form, Input } from "./styles"
export default connectSearchBox(({ refine, placeholder, ...rest }) => {
  let typingTimer
  return (
    <Form>
      <Input
        type="text"
        placeholder={placeholder || "Search"}
        aria-label="Search"
        onChange={e => {
          const value = e.target.value
          clearTimeout(typingTimer)
          typingTimer = setTimeout(() => refine(value), 200)
        }}
        {...rest}
      />
    </Form>
  )
})
