export const orgaCreator = ({
  siteUrl,
  siteTitle,
  siteTitleAlt,
  siteDescription,
  homeURL,
  siteLogo,
  siteLogoSmall,
}) => input => ({
  "@context": "http://schema.org",
  "@id": `${siteUrl}/#${input}`,
  "@type": "Organization",
  address: {
    "@type": "PostalAddress",
    addressCountry: "DO",
    addressLocality: "",
    postalCode: "",
  },
  name: siteTitle,
  alternateName: siteTitleAlt,
  description: siteDescription,
  url: homeURL,
  email: "omar.gaston.c@gmail.com",
  founder: "Omar Gaston",
  foundingDate: "2019-02-08",
  foundingLocation: "Dominican Republic",
  image: {
    "@type": "ImageObject",
    url: siteLogo,
    height: "512",
    width: "512",
  },
  logo: {
    "@type": "ImageObject",
    url: siteLogoSmall,
    height: "60",
    width: "60",
  },
  sameAs: [
    "https://github.com/ogaston",
    "https://www.linkedin.com/in/omar-gaston-chalas/",
    "https://twitter.com/ogastonc",
  ],
})

export const getSchemaArticle = ({
  siteUrl,
  createdAt,
  updatedAt,
  title,
  URL,
  htmlLang,
  image,
  description,
}) => ({
  "@context": "http://schema.org",
  "@type": "Article",
  author: {
    "@id": `${siteUrl}/#identity`,
  },
  copyrightHolder: {
    "@id": `${siteUrl}/#identity`,
  },
  copyrightYear: createdAt,
  creator: {
    "@id": `${siteUrl}/#creator`,
  },
  publisher: {
    "@id": `${siteUrl}/#creator`,
  },
  datePublished: createdAt,
  dateModified: updatedAt,
  description,
  headline: title,
  inLanguage: htmlLang,
  url: URL,
  name: title,
  image: {
    "@type": "ImageObject",
    url: image,
  },
  mainEntityOfPage: URL,
})
