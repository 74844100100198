import React from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { Calendar } from "styled-icons/octicons/Calendar"
import Labels from "../Labels"
import { injectIntl } from "react-intl"

export const PageHit = clickHandler => ({ hit }) => (
  <div>
    <Link to={"en/" + hit.slug} onClick={clickHandler}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)

export const BlogHit = clickHandler =>
  injectIntl(({ hit, intl }) => (
    <div className="rounded overflow-hidden shadow-lg bg-white w-full text-black p-3 mt-4">
      <Link to={"/" + intl.locale + "/" + hit.slug} onClick={clickHandler}>
        <div className="flex mb-4">
          <div className="w-1/2">
            <h4>
              <Highlight attribute="title" hit={hit} tagName="mark" />
            </h4>
          </div>
          <div className="w-1/2">
            <Calendar size="1em" />
            <Highlight
              attribute="createdAt"
              hit={{
                ...hit,
                createdAt: new Date(hit.createdAt).toDateString(),
              }}
              tagName="mark"
            />
          </div>
        </div>
      </Link>
      <br />
      <Snippet
        style={{ color: "gray" }}
        attribute="excerpt"
        hit={hit}
        tagName="mark"
      />
      <br />
      <br />

      <div>
        <Labels list={hit.labels.split(",")} />
      </div>
    </div>
  ))
